<template>
  <div>
    <div class="content_area">
      <el-button
        type="warning"
        style="position:absolute; right: 0; top: 32px; cursor: pointer; z-index: 999"
        size="small"
        v-if="USER_INFO.user_type == 2 && activeName == 'first'"
        @click="consultingFunc"
        >新建舱位咨询</el-button
      >
      <el-tabs
        v-model="activeName"
        type="card"
        @tab-click="handleClick"
        class="tabs"
      >
        <el-tab-pane label="拼箱" name="second"></el-tab-pane>
        <el-tab-pane label="整箱" name="first"></el-tab-pane>

        <!--        <el-tab-pane label="整箱危险品" name="third"></el-tab-pane>-->
      </el-tabs>
      <div v-show="activeName == 'second'">
        <div class="freightContent">
          <el-row>
            <el-col :span="4" class="searchLabel">起运港</el-col>
            <el-col :span="4" class="searchLabel">目的港</el-col>
          </el-row>
          <el-row class="searchRow">
            <el-col :span="4">
              <portSelect
                v-model="queryParams.from_port"
                placeTitle="请输入起运港"
                clearable
                @input="from_port_input"
              />
            </el-col>
            <el-col :span="4">
              <portSelect
                v-model="queryParams.dest_port"
                placeTitle="请输入目的港"
                clearable
                @input="dest_port_input"
              />
            </el-col>
            <el-col :span="2">
              <el-checkbox
                v-model="queryParams.is_direct"
                :true-label="1"
                :false-label="0"
                >直达</el-checkbox
              >
            </el-col>
            <el-col :span="4">
              <el-button
                type="success"
                class="searchBtn"
                size="small"
                @click="handleSearch"
                >查询</el-button
              >
            </el-col>
          </el-row>
          <div class="dataList" v-loading="loading" >
            <div class="dataLabel">
              <div class="dataLabel1">起运港</div>
              <div class="dataLabel2">中转港</div>
              <div class="dataLabel3">目的港</div>
              <div class="dataLabel4">单价</div>
              <div class="dataLabel5">船期</div>
              <div class="dataLabel6">航程</div>
              <div class="dataLabel7">最小货量</div>
              <div class="dataLabel8"></div>
              <div class="dataLabel8"></div>
            </div>
            <div class="dataItems">
              <div
                class="dataItem"
                v-for="(item, index) in dataList"
                :key="index"
              >
                <div class="dataLabel1">
                  <div class="portEn" :title="item.from_port | textFormat">
                    {{ item.from_port | textFormat }}
                  </div>
                  <div
                    class="portZh"
                    :title="item.from_port_name_zh | textFormat"
                  >
                    {{ item.from_port_name_zh | textFormat }}
                  </div>
                </div>
                <div class="dataLabel2 myStye_jt">
                  <div>
                    <span
                      v-if="item.trans_port"
                      :title="item.trans_port | textFormat"
                      >{{ item.trans_port | textFormat }} 转</span
                    >
                    <span v-else>直达</span>
                  </div>
                  <img
                    class="line_img"
                    src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/ymp/rightarrow.png"
                    alt=""
                  />
                  <!--                隐藏航线-->
                  <!--                <div :title="item.shipping_line | textFormat">{{ item.shipping_line | textFormat}}</div>-->
                </div>
                <div class="dataLabel3">
                  <div class="portEn" :title="item.dest_port | textFormat">
                    {{ item.dest_port | textFormat }}
                  </div>
                  <div
                    class="portZh"
                    :title="item.dest_port_name_zh | textFormat"
                  >
                    {{ item.dest_port_name_zh | textFormat }}
                  </div>
                </div>
                <div class="dataLabel4" v-if="USER_INFO && USER_INFO.user_type == 2">
                  <div class="unit">
                    市场价 USD
                    <span
                      :class="USER_INFO ? 'price' : 'mohu'"
                      style="color: red"
                      v-if="item.price_lcl_dangerous < 0"
                      :title="USER_INFO ? item.price_lcl_dangerous.toFixed():'' | textFormat"
                    >
                      ({{ Math.abs(item.price_lcl_dangerous).toFixed() | textFormat }})
                    </span>
                    <span
                      :class="USER_INFO ? 'price' : 'mohu'"
                      v-else
                      :title="USER_INFO ? item.price_lcl_dangerous.toFixed():'' | textFormat"
                    >
                      {{ item.price_lcl_dangerous.toFixed() | textFormat }}
                    </span>
                  </div>
                  <div class="unit">
                    {{ item.auth_type == 1 ? '直客价':'最低价' }} USD
                    <span
                      :class="USER_INFO ? 'price' : 'mohu'"
                      style="color: red"
                      v-if="item.price_lcl_general < 0"
                      :title="USER_INFO ? item.price_lcl_general.toFixed():'' | textFormat"
                    >
                      ({{ Math.abs(item.price_lcl_general).toFixed() | textFormat }})
                    </span>
                    <span
                      :class="USER_INFO ? 'price' : 'mohu'"
                      v-else
                      :title="USER_INFO ? item.price_lcl_general.toFixed():'' | textFormat"
                    >
                      {{ item.price_lcl_general.toFixed() | textFormat }}
                    </span>
                  </div>
                </div>
                <div class="dataLabel4" v-else>
                  <div class="unit">
                    USD
                    <span
                      :class="USER_INFO ? 'price' : 'mohu'"
                      style="color: red"
                      v-if="item.price_lcl_dangerous < 0"
                      :title="USER_INFO ? item.price_lcl_dangerous.toFixed():'' | textFormat"
                    >
                      ({{ Math.abs(item.price_lcl_dangerous).toFixed() | textFormat }})
                    </span>
                    <span
                      :class="USER_INFO ? 'price' : 'mohu'"
                      v-else
                      :title="USER_INFO ? item.price_lcl_dangerous.toFixed():'' | textFormat"
                    >
                      {{ item.price_lcl_dangerous.toFixed() | textFormat }}
                    </span>
                  </div>
                </div>
                <div class="dataLabel5 color000" :title="item.shipping_date">
                  {{ item.shipping_date | weekDaysFormat }}
                </div>
                <div class="dataLabel6 color000" :title="item.days">
                  {{ item.days | daysFormat }}
                </div>
                <div
                  class="dataLabel7 color000"
                  :title="item.mini_cargo | textFormat"
                >
                  {{ item.mini_cargo | textFormat }} CBM
                </div>
                <div class="dataLabel8">
                  <div class="internal" v-if="item.auth_type == 1">直客</div>
                </div>
                <div class="dataLabel8 point" @click="handleDetail(item)">
                  运价详情 >
                </div>
              </div>
              <noDataImg v-if="!loading && dataList.length === 0"></noDataImg>
            </div>
          </div>
        </div>
        <el-pagination
          :hide-on-single-page="true"
          class="content_area ymp_pagination"
          style="text-align: right; padding-bottom: 20px"
          layout="prev, pager, next"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="limit"
          :total="count"
        >
        </el-pagination>
      </div>
      <div v-show="activeName == 'first'">
        <div class="FCLSearch" style="margin-bottom:24px">
          <el-row class="searchRow">
            <el-col :span="4">
              <p class="searchTitle">起运港</p>
              <portSelect
                v-model="fclParams.polName"
                placeTitle="请输入起运港"
                clearable
                @input="pol_input"
              />
            </el-col>
            <el-col :span="4">
              <p class="searchTitle">目的港</p>
              <portSelect
                v-model="fclParams.podName"
                placeTitle="请输入目的港"
                clearable
                @input="pod_input"
              />
            </el-col>
            <el-col :span="4">
              <p class="searchTitle">船公司</p>
              <el-select
                v-model="fclParams.company"
                filterable
                :placeholder="'请输入船公司'"
                remote
                size="small"
                :remote-method="remoteMethod"
                clearable
                :loading="companyLoading"
              >
                <el-option-group
                  v-for="group in companYoptions"
                  :key="group.index"
                  :label="group.index"
                >
                  <el-option
                    v-for="item in group.info"
                    :key="item.id"
                    :label="item.lable"
                    :value="item.id"
                  >
                  </el-option>
                </el-option-group>
              </el-select>
            </el-col>
            <el-col :span="6">
              <p class="searchTitle">有效期</p>
              <div class="time">
                <el-date-picker
                  :clearable="false"
                  v-model="fclParams.start"
                  type="date"
                  placeholder="选择日期"
                  size="small"
                  style="margin-right: 20px"
                  @change="startChange"
                >
                  <!-- :picker-options="startPickerOptions" -->
                </el-date-picker>

                <el-date-picker
                  @change="endChange"
                  :disabled="fclParams.start ? false : true"
                  size="small"
                  v-model="fclParams.end"
                  type="date"
                  placeholder="选择日期"
                  :picker-options="endPickerOptions"
                >
                </el-date-picker>
              </div>
            </el-col>
            <el-col :span="2" style="padding-left: 20px">
              <p class="searchTitle"></p>
              <el-checkbox
                v-model="fclParams.is_direct"
                :true-label="1"
                :false-label="0"
                >直达</el-checkbox
              >
            </el-col>
            <el-col :span="2">
              <p class="searchTitle">UNNO</p>
              <el-input v-model="fclParams.unno" size="small"></el-input>
              <!-- <el-select
                v-model="fclParams.unno"
                filterable
                remote
                placeholder=" "
                size="small"
                :remote-method="remoteUnno"
                clearable
                :loading="unnoLoading"
              >
                <el-option
                  v-for="item in unnoOptions"
                  :key="item.id"
                  :label="item.pscn"
                  :value="item.un_no"
                >
                </el-option>
              </el-select> -->
            </el-col>
            <el-col :span="2">
              <p class="searchTitle"></p>
              <el-button
                style="float: right"
                type="success"
                class="searchBtn"
                size="small"
                @click="handleSearchFcl(true)"
                >查询</el-button
              >
            </el-col>
          </el-row>
        </div>
        <!-- <div class="swiper-container">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide"
              v-for="(item, index) in timeArr"
              :key="index"
            >
              {{ item.time | secondFormat("L") }}<br />
              {{ item.week }}
            </div>
          </div>
          <div class="swiper-button-next"></div>
          <div class="swiper-button-prev"></div>
        </div> -->
        <div class="dataList" v-loading="loading">
          <div class="dataLabel">
            <div class="dataLabel1">起运港</div>
            <div class="dataLabel2">中转港/航线</div>
            <div class="dataLabel3">目的港</div>
            <div class="dataLabel4">船公司</div>
            <div class="dataLabel4" v-if="USER_INFO.user_type == 2">订舱来源</div>
            <div class="dataLabel6">航程</div>
            <div class="dataLabe19">20GP</div>
            <div class="dataLabe20">40GP</div>
            <div class="dataLabe21">40HQ</div>
            <!-- <div class="dataLabe22">45HQ</div> -->
            <div class="dataLabel8"></div>
          </div>
          <div class="dataItems">
            <div v-for="(item, index) in fclData" :key="index">
              <div class="dataItem">
                <div class="dataLabel1">
                  <div
                    class="portEn"
                    :title="item.from_port_name_en | textFormat"
                  >
                    {{ item.from_port_name_en | textFormat }}
                  </div>
                  <div
                    class="portZh"
                    :title="item.from_port_name_zh | textFormat"
                  >
                    {{ item.from_port_name_zh | textFormat }}
                  </div>
                </div>
                <div class="dataLabel2 myStye_jt_2">
                  <div>
                    <span
                      v-if="item.trans_port_name_en"
                      :title="item.trans_port_name_en | textFormat"
                    >
                      {{ item.trans_port_name_en | textFormat }}转</span
                    >
                    <span v-else>直达</span>
                  </div>
                  <!-- <div class="line">
                    <span class="jiantou">></span>

                  </div> -->
                  <img
                    class="line_img"
                    src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/ymp/rightarrow.png"
                    alt=""
                  />
                  <!--                隐藏航线-->
                  <div :title="item.shipping_line_name_zh | textFormat">
                    {{ item.shipping_line_name_zh | textFormat }}
                  </div>
                </div>
                <div class="dataLabel3">
                  <div
                    class="portEn"
                    :title="item.dest_port_name_en | textFormat"
                  >
                    {{ item.dest_port_name_en | textFormat }}
                  </div>
                  <div
                    class="portZh"
                    :title="item.dest_port_name_zh | textFormat"
                  >
                    {{ item.dest_port_name_zh | textFormat }}
                  </div>
                </div>
                <div class="dataLabel4">
                  <img
                    :src="item.logo||default_logo"
                    style="width: 40px; height: 40px"
                    alt=""
                    srcset=""
                  />
                  <p>{{ item.shipping_company_code | textFormat }}</p>
                </div>
                <div class="dataLabel4" v-if="USER_INFO.user_type == 2">
                  {{item.booking_agent_code | textFormat}}
                </div>

                <div class="dataLabel6" :title="item.days">
                  {{ item.days | daysFormat }}
                </div>
                <div class="dataLabe19" v-if="!item.reject_20gp">
                  <span
                    :class="user_type == 1 ? 'mohu' : 'price'"
                    >{{ item.price_20gp.toFixed() | textFormat }}</span
                  >

                  <br />{{ item.currency_code }}
                </div>
                <div class="dataLabe19" v-else>-</div>
                <div class="dataLabe20" v-if="!item.reject_40gp">
                  <span
                    :class="user_type == 1 ? 'mohu' : 'price'"
                    >{{ item.price_40gp.toFixed() | textFormat }}</span
                  >

                  <br />{{ item.currency_code }}
                </div>
                <div class="dataLabe19" v-else>-</div>
                <div class="dataLabe21" v-if="!item.reject_40hc">
                  <span
                    :class="user_type == 1 ? 'mohu' : 'price'"
                    >{{ item.price_40hc.toFixed() | textFormat }}</span
                  >

                  <br />{{ item.currency_code }}
                </div>
                <div class="dataLabe19" v-else>-</div>

                <div class="dataLabel8 point" @click="handleDetail(item)">
                  运价详情 >
                </div>
              </div>
              <div class="dataBottom">
                <span> 船期： {{ item.shipping_date | weekDaysFormat }}</span>
                <span>
                  挂靠码头： {{ item.affiliated_terminal | textFormat }}</span
                >
                <span>
                  <span v-if="item.lock_price_way==1 || item.lock_price_way==2">有效期：{{ item.validity_start | secondFormat("L1") }} 至 {{ item.validity_end | secondFormat("L1") }}</span> 
                      <span v-else-if="item.lock_price_way==3">锁价：{{  item.vessel | textFormat }} / {{  item.voyage | textFormat }}</span> 
                      <span v-else>有效期：-</span>
                </span
                >
              </div>
            </div>

            <noDataImg v-if="!loading && fclData.length === 0"></noDataImg>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import portSelect from "~ymp/components/common/portSelect";
import { portFormat } from "~ymp/plugins/filters";

export default {
  name: "list",
  components: { portSelect },
  data() {
    let that = this;
    return {
      default_logo:"https://ym-attached-file.oss-cn-shanghai.aliyuncs.com/miniProgram/ship.png?versionId=CAEQIxiBgMCgot3e0BciIDczYThjOWU5NzZlYzRmOTA4Yzk4Yjk0MDUwZWMxZmUz",
      swiper: null,
      startPickerOptions: {
        disabledDate: (time) => {
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
      endPickerOptions: {
        disabledDate: (time) => {
          // 设置可选择的日期为今天之后的一个月内
          let curDate = this.fclParams.start.getTime();
          // 这里算出一个月的毫秒数,这里使用30的平均值,实际中应根据具体的每个月有多少天计算
          let day = 30 * 24 * 3600 * 1000;
          let dateRegion = curDate + day;
          return (
            time.getTime() <
              this.$moment(this.fclParams.start.getTime()).startOf("day") ||
            time.getTime() > dateRegion
          );
        },
      },
      activeName: "second",
      queryParams: {
        from_port: "SHANGHAI",
        dest_port: "",
        is_direct: 0,
        from_port_id: "4179",
        dest_port_id: "",
      },
      companYoptions: [],
      unnoOptions: [],
      companyLoading: false,
      fclParams: {
        polName: "SHANGHAI",
        pol: "4179",
        podName: "",
        pod: "",
        company: "",
        start: "",
        end: "",
        is_direct: 0,
        unno: "",
      },
      filtersStart: "",
      filtersEnd: "",
      dataList: [],
      currentPage: 1,
      limit: 10,
      count: null,
      loading: false,
      unnoLoading: false,
      timeArr: [],
      fclData: [],
      user_type: 1,
    };
  },
  mounted() {
    this.user_type = this.USER_INFO ? this.USER_INFO.user_type : 1;
    this.fclParams.start = new Date(this.$moment());
    this.fclParams.end = new Date(this.$moment().add(14, "days"));

    if (this.$route.query && this.$route.query.from_port) {
      this.queryParams.from_port = this.$route.query.from_port;
      this.queryParams.from_port_id = this.$route.query.from_port_id;
      this.fclParams.pol = this.$route.query.from_port_id;
      this.fclParams.polName = this.$route.query.from_port;
    }
    if (this.$route.query && this.$route.query.dest_port) {
      this.queryParams.dest_port = this.$route.query.dest_port;
      this.queryParams.dest_port_id = this.$route.query.dest_port_id;
      this.fclParams.pod = this.$route.query.dest_port_id;
      this.fclParams.podName = this.$route.query.dest_port;
    }
    if (this.$route.query && this.$route.query.unno) {
      this.fclParams.unno = this.$route.query.unno;
    }
    if (this.$route.query && this.$route.query.activeName) {
      this.activeName = this.$route.query.activeName;
    }
    if (this.$route.query && this.$route.query.activeName == "first") {
      this.$log({
          type: 2,
          topic: "ymp.inquiry",
          id: "ymp.inquiry.fcl",
        });
      this.handleSearchFcl();
    } else {
      this.$log({
        type: 2,
        topic: "ymp.inquiry",
        id: "ymp.inquiry.lcl",
      });
      this.getDataList();
    }
  },
  watch: {
    activeName(val, oldVal) {
      //普通的watch监听
      return;
      if (!this.swiper) {
        this.$nextTick((res) => {
          let that = this;
          this.swiper = new Swiper(".swiper-container", {
            slidesPerView: 7,
            initialSlide: 0,
            touchRatio: 0,
            direction: this.getDirection(),
            navigation: {
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            },
            observer: true, //修改swiper自己或子元素时，自动初始化swiper
            observeParents: false, //修改swiper的父元素时，自动初始化swiper
            onSlideChangeEnd: function (swiper) {
              swiper.update();
              this.swiper.startAutoplay();
              this.swiper.reLoop();
            },
            on: {
              resize: function () {
                this.swiper.changeDirection(this.getDirection());
              },
              slideChangeTransitionEnd: function () {
                let index = this.activeIndex;
                that.filtersStart = that.timeArr[index].time;
                that.filtersEnd = that.timeArr[index + 6].time;
                that.handleSearchFcl();
              },
            },
          });
          this.initSwiper();

          setTimeout(() => {
            this.initSwiper();
          }, 0);
        });
      }
    },
  },
  methods: {
    consultingFunc(){
      this.$router.push({
        path: '/consulting'
      })
    },
    startChange(e) {
      if (this.$moment(e).unix() > this.$moment(this.fclParams.end).unix()) {
        this.$message({
          message: "开始时间不能晚于结束时间",
          type: "warning",
        });
        this.fclParams.start = new Date(this.$moment());
      }

      this.initSwiper();
    },
    endChange() {
      this.initSwiper();
    },
    initSwiper() {
      let arr = [];
      
      let m1 = this.$moment(this.fclParams.start);
      //获取需要对比的时间
      let m2 = this.$moment(
        this.$moment(this.fclParams.end).format("YYYY-MM-DD")
      ).endOf("day");
      //计算相差多少天 day可以是second minute
      let day = m2.diff(m1, "day") + 2;
      for (let index = 0; index < day; index++) {
        if (
          this.$moment(this.fclParams.start).add(index, "days") <=
          this.$moment(
            this.$moment(this.fclParams.end).format("YYYY-MM-DD")
          ).endOf("day")
        ) {
          arr.push({
            time: this.$moment(this.fclParams.start).add(index, "days").unix(),
            week: this.getWeek(
              this.$moment(this.fclParams.start).add(index, "days")
            ),
          });
        }
      }
      this.timeArr = arr;
      if (this.timeArr.length <= 7) {
        this.filtersStart = this.timeArr[0].time;
        this.filtersEnd = this.timeArr[this.timeArr.length - 1].time;
      } else {
        this.filtersStart = this.timeArr[0].time;
        this.filtersEnd = this.timeArr[6].time;
      }

      // setTimeout((res) => {
      //   this.swiper.slideTo(0, 500, false);
      // }, 500);
      this.handleSearchFcl();
    },
    getWeek(date) {
      // 参数时间戳
      let week = this.$moment(date).day();
      switch (week) {
        case 1:
          return "周一";
        case 2:
          return "周二";
        case 3:
          return "周三";
        case 4:
          return "周四";
        case 5:
          return "周五";
        case 6:
          return "周六";
        case 0:
          return "周日";
      }
    },
    getDirection() {
      var windowWidth = window.innerWidth;
      var direction = window.innerWidth <= 760 ? "vertical" : "horizontal";

      return direction;
    },
    async remoteMethod(e) {
      this.companyLoading = true;
      let data = await this.$store.dispatch("API_bookingSpace/getConpanyList", {
        name: e,
      });
      this.companyLoading = false;
      data.data.forEach((res) => {
        res.info.forEach((ee) => {
          ee.lable = `[${ee.code}] ${ee.name_en}`;
        });
      });

      this.companYoptions = data.data;
    },
    async remoteUnno(e) {
      this.unnoLoading = true;
      let data = await this.$store.dispatch("API_bookingSpace/getUnno", {
        un_no: e,
      });
      this.unnoLoading = false;
      this.unnoOptions = data.data;
    },
    handleClick() {
      if (this.activeName !== "second") {
        this.initSwiper();
      }
      if(this.activeName !== "second"){
        this.$log({
          type: 2,
          topic: "ymp.inquiry",
          id: "ymp.inquiry.fcl",
        });
      }else{
        this.$log({
          type: 2,
          topic: "ymp.inquiry",
          id: "ymp.inquiry.lcl",
        });
      }
    },
    async getList(showTips) {
      if (!this.fclParams.pol || !this.fclParams.pod) {
        if(showTips){
          this.$message.warning(`请输入目的港&起运港`);
        }
        this.fclData = [];
        return;
      }
      this.loading = true;
      let params = {
        nopage: 1,
        user_type: this.USER_INFO ? this.USER_INFO.user_type : 1,
      };
      if (this.fclParams.unno) {
        params.dangerous_status = 1;
      } else {
        params.dangerous_status = 0;
      }
      params.from_port = this.fclParams.pol;
      params.dest_port = this.fclParams.pod;
      params.shipping_company = this.fclParams.company;
      // params.validity_start = this.filtersStart;  fclParams.end
      // params.validity_end = this.filtersEnd;
      params.validity_start = this.$moment(this.fclParams.start).unix();  
      params.validity_end = this.$moment(this.fclParams.end).unix();
      if (this.fclParams.is_direct) {
        params.is_direct = 1;
      }
      if (this.fclParams.unno) {
        params.unno = this.fclParams.unno;
      }
      
      let res = await this.$store.dispatch(
        "API_freight/getFclInfoList",
        params
      );
      this.$log({
        type: 1,
        topic: "ymp.inquiry",
        id: "ymp.inquiry.fcl.search",
        content: JSON.stringify({
          pol: this.fclParams.pol || '',
          POD: this.fclParams.pod || '',
          direct: this.fclParams.is_direct == 1 ? 1:0,
          company: this.fclParams.company || '',
          unno: this.fclParams.unno || ''
        }),
      });
      this.fclData = res.data.map((item) => {
        return item;
      });
      this.loading = false;
    },
    // 客户端
    async handleSearchFcl(showTips) {
      if (this.fclParams.unno) {
        let res = await this.$store.dispatch("API_freight/check_unno", {
          unno: this.fclParams.unno,
        });
        if (res.success) {
          this.getList(showTips);
        } else {
          this.$confirm(
            "您输入的UNNO有误，如果继续查询，将展示普通货物价格。",
            "提示",
            {
              confirmButtonText: "查看普货运价",
              cancelButtonText: "更改UNNO",
              type: "warning",
            }
          )
            .then(() => {
              this.fclParams.unno = "";
              this.getList(showTips);
            })
            .catch(() => {
              return;
            });
        }
      } else {
        this.getList(showTips);
      }
    },
    async getDataList() {
      // 客户端
      this.loading = true;
      let params = {};
      params = {
        from_port: this.queryParams.from_port_id,
        dest_port: this.queryParams.dest_port_id,
        start: this.currentPage - 1,
        limit: this.limit,
      };
      if (this.queryParams.is_direct == 1) {
        params.is_direct = 1;
      }
      if(this.USER_INFO.id){
        params.user_id = this.USER_INFO.id;
      }
      let res = await this.$store.dispatch(
        "API_freight/getFreightList",
        params
      );
      this.loading = false;
      if (res.success) {
        this.dataList = res.data;
        this.count = res.count;
      }
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getDataList();
    },
    handleSearch() {
      this.$log({
        type: 1,
        topic: "ymp.inquiry",
        id: "ymp.inquiry.lcl.search",
        content: JSON.stringify({
          pol: this.queryParams.from_port_id || '',
          POD: this.queryParams.dest_port_id || '',
          direct: this.queryParams.is_direct == 1 ? 1:0
        }),
      });
      this.currentPage = 1;
      this.getDataList();
    },
    handleDetail(item) {
      if (this.activeName == "second") {
        this.$log({
          type: 1,
          topic: "ymp.inquiry",
          id: "ymp.inquiry.search.detail",
          content: JSON.stringify({
            id: item.id || '',
          }),
        });
        if (!this.IS_LOGIN) {
          return this.$GLOBALEVENT.$emit("SHOWGLOBALLOGIN", {
            waitTodo: () => {
              this.handleDetail(item);
            },
          });
        }
        let routeData = this.$router.resolve({
          query: {
            id: item.id,
            shipping_line_id: item.shipping_line_id,
            min: item.mini_cargo,
          },
          path: "/freightSearchDetailsCFS",
        });
        window.open(routeData.href, "_blank");
      } else {
        this.$log({
          type: 1,
          topic: "ymp.inquiry",
          id: "ymp.inquiry.fcl.detail",
          content: JSON.stringify({
            id: item.id || '',
          }),
        });
        if (!this.IS_LOGIN) {
          return this.$GLOBALEVENT.$emit("SHOWGLOBALLOGIN", {
            waitTodo: () => {
              this.to_zheng_detail(item);
            },
          });
        } else {
          this.to_zheng_detail(item);
        }
      }
    },
    // 拼箱 详情
    to_zheng_detail(item){
        if (this.USER_INFO.user_type == 2) {
          // 销售
          let routeData = this.$router.resolve({
            query: {
              id: item.id,
              shipping_line_id: item.shipping_line_id,
              unno: this.fclParams.unno,
              is_dangerous: this.fclParams.unno ? 1 : 0,
            },
            path: "/detail_inquery",
          });
          window.open(routeData.href, "_blank");
        } else {
          // 客户
          let routeData = this.$router.resolve({
            query: {
              id: item.id,
              shipping_line_id: item.shipping_line,
              unno: this.fclParams.unno,
            },
            path: "/freightSearchDetailsFcl",
          });
          window.open(routeData.href, "_blank");
        }
    },

    from_port_input(val) {
      if (val === "") val = null;
      let num = Number(val);
      if (!isNaN(num) || val === "") {
        this.queryParams.from_port_id = val;
      }
    },
    pol_input(val) {
      if (val === "") val = null;
      let num = Number(val);
      if (!isNaN(num) || val === "") {
        this.fclParams.pol = val;
      }
    },
    pod_input(val) {
      if (val === "") val = null;
      let num = Number(val);
      if (!isNaN(num) || val === "") {
        this.fclParams.pod = val;
      }
    },
    dest_port_input(val) {
      if (val === "") val = null;
      let num = Number(val);
      if (!isNaN(num) || val === "") {
        this.queryParams.dest_port_id = val;
      }
    },
  },
};
</script>

<style scoped lang="less">
.swiper-wrapper {
  margin: 30px 0;
}
.swiper-slide {
  text-align: center;
  border-right: 1px solid #ccc;
}
.swiper-slide:last-child {
  border: none;
}
.tabs {
  padding-top: 32px;

  /deep/ .is-active {
    color: #91be42;
  }

  /deep/ .el-tabs__item:hover {
    color: #91be42;
  }
}
.time {
  display: flex;
  justify-content: space-between;
}
.dataList {
  background: #f3f6f8;
  padding: 16px 0;
  .dataLabel {
    width: 100%;
    height: 50px;
    line-height: 50px;
    display: flex;
    //background: #91be42;
    background: #909399;
    padding: 0 24px;
    div {
      color: #ffffff;
      font-size: 16px;
      text-align: center;
    }
  }
  .dataItems {
    min-height: 500px;
    position: relative;
    div {
      overflow: hidden;
      white-space: nowrap !important;
      text-overflow: ellipsis;
    }
  }
  .dataItem {
    display: flex;
    width: 100%;
    background: #ffffff;
    padding: 20px 24px;
    margin-top: 16px;
    text-align: center;
    align-items: center;
    border-bottom: 1px solid #f2f2f2;
  }
  .dataBottom {
    background: #fff;
    font-size: 12px;
    padding: 10px 20px;
    span {
      display: inline-block;
      width: 200px;
    }
  }
  .dataLabel1 {
    width: 180px;
    margin-right: 10px;
  }
  .dataLabel2 {
    width: 180px;
    margin-right: 10px;
  }
  .myStye_jt {
    height: 45px;
    position: relative;
    padding-top: 19px;
    > div {
      position: absolute;
      width: 100%;
      top: 10px;
    }
    .line_img {
      width: 143px;
      height: 6px;
    }
  }
  .myStye_jt_2 {
    height: 59px;
    position: relative;
    padding-top: 19px;
    > div:first-child {
      position: absolute;
      width: 100%;
      top: 10px;
    }
    .line_img {
      width: 189px;
      height: 6px;
    }
  }
  .dataLabel3 {
    width: 180px;
    margin-right: 10px;
  }
  .dataLabel4 {
    width: 180px;
    margin-right: 10px;
    text-align: center;
  }
  .dataLabel5 {
    width: 150px;
    margin-right: 10px;
  }
  .dataLabel6 {
    width: 150px;
    margin-right: 10px;
  }
  .dataLabel7 {
    width: 150px;
    margin-right: 10px;
  }
  .dataLabe19,
  .dataLabe20,
  .dataLabe21,
  .dataLabe22 {
    width: 100px;
    text-align: center;
  }
  .dataLabel8 {
    width: 100px;
    text-align: right;
    color: #027fff;
    font-weight: bold;
  }
  .portEn {
    color: #91be42;
    font-size: 18px;
    font-weight: 800;
  }
  .portZh {
    margin-top: 6px;
    color: #000000;
  }
  .line {
    width: 186px;
    height: 11px;

    // height: 1px;
    // background: #cdcdcd;
    // margin: 5px auto;
    // position: relative;
    // overflow: initial !important;
    // .jiantou {
    //   position: absolute;
    //   color: #cdcdcd;
    //   right: -6px;
    //   top: -10px;
    // }
  }
  .price {
    font-size: 17px;
    // color: #f79e46;
    color: #3c5058;
  }
  .unit {
    margin-top: 4px;
    color: rgba(0, 0, 0, 0.65);
  }
  .color000 {
    color: rgba(0, 0, 0, 0.65);
  }
}
.freightContent {
  margin-bottom: 40px;
  .searchLabel {
    color: #1f292e;
  }
  .searchRow {
    display: flex;
    align-items: center;
    padding-top: 12px;
    padding-bottom: 24px;
    .searchBtn {
      background-color: #91be42;
      border-color: #91be42;
    }
  }
}
.FCLSearch {
  .searchTitle {
    width: 100%;
    height: 30px;
  }
}
.mohu {
  color: transparent !important;
  -moz-user-select: none; //火狐
  -webkit-user-select: none; //webkit浏览器（Chrome，Opera，Safari等）
  -ms-user-select: none; //IE10
  -khtml-user-select: none; //早期浏览器
  user-select: none;
  text-shadow: #3c5058 0 0 6px;
}
.content_area{
  position: relative;
}
.internal{ // 内部价
  text-align: center;
  line-height: 50px;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background: #f26161;
  color: white;
  font-size: 12px;
}
</style>
